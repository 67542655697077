import React from 'react'
import { HelmetFunc } from 'components/PageMetaData'
import { RunningLine } from 'components/RunningLine'
import { useTranslation } from 'react-i18next'
import { Layout } from 'layout/Layout'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { ExamplesOfProjects3d } from 'blocks/exampleOfProjects3d'
import styled from 'styled-components'
import { IconListQuestion } from 'components/IconListQuestion'
import { LeftSidebar, RightSidebar } from 'components/SideBar'
import { backgroundColors, colors } from 'styles/colors'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { displayWidth } from 'styles/width'
import { Container } from 'components/Container'
import { graphql } from 'gatsby'
import { IItem } from 'types/interfaces'
import { getDataByLanguage } from 'utils/getDataByLanguage'

const pageMetadata = {
    uk: {
        title: 'Зразок дизайн проекту інтер`єру 3D',
        description:
            'Проект виконується в 3D максимально наочно і дозволяє опрацювати кожну деталь інтер`єру з різних ракурсів та отримати точні креслення для ремонту та підбору меблів',
    },
    ru: {
        title: 'Образец дизайн проекта интерьера 3D',
        description:
            'Проект выполняется в 3D максимально наглядно и позволяет проработать каждую деталь интерьера с различных ракурсов и получить точные чертежи для ремонта и подбора мебели',
    },
    en: {
        title: 'Sample Interior Design Project 3D',
        description:
            'The project is carried out in 3D as clearly as possible and allows you to work out every detail of the interior from different angles and get accurate drawings for the repair and selection of furniture',
    },
}

const ProjectStructureWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`

const IconListStyled = styled(IconListQuestion)`
    background-color: ${(props) =>
        props.background || backgroundColors.contact};
    border-bottom: 1px solid ${colors.dark};
    h2 {
        font-size: 30px;
        line-height: 35px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
    }
`

const Example3dPage = ({
    data,
}: {
    data: {
        allProjectStructure3DYaml: { items: IItem }
        allProject3DYaml: { items: IItem }
    }
}) => {
    const { t, i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allProjectStructure3DYaml,
        i18n.language
    )

    const faqData = getDataByLanguage(data.allProject3DYaml, i18n.language)
    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <ExamplesOfProjects3d />
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.index} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={faqData.items}
                        fill={colors.accentText}
                        background={backgroundColors.index}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <RunningLine>{t('designProject99')}</RunningLine>
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.index} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={projectStructureData.items}
                        fill={colors.accentText}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormExample',
                    eventCategory: 'FormExample',
                }}
            />
        </Layout>
    )
}

export default Example3dPage

export const query = graphql`
    query {
        allProjectStructure3DYaml {
            edges {
                node {
                    items {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allProject3DYaml {
            edges {
                node {
                    items {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
